<template>
  <app-modal v-if="isShowModal" class="auth-modal" @close="onCloseModal">
    <div class="auth-modal__block auth-modal__block_blue">
      <div class="auth-modal__icon">
        <img
          src="@/assets/icons/SVG/qr-code.svg"
          alt=""
          class="auth-modal__image"
        />
      </div>
      <span>
        Чтобы получить доступ ко всем услугам вашего отеля, отсканируйте QR код
        у вас в номере или на стойке ресепшена
      </span>
    </div>
    <div class="auth-modal__flex">
      <div class="auth-modal__icon auth-modal__icon_blue">
        <img
          src="@/assets/icons/SVG/face-wink.svg"
          alt=""
          class="auth-modal__image"
        />
      </div>
      <span> Получите код на ресепшене для полного доступа к приложению </span>
    </div>
    <app-button
      class="auth-modal__invite"
      theme="common"
      text="Вход по приглашению"
      data-cy="invite"
      @click="onEnterInvite"
    >
      <img alt="" src="@/assets/icons/SVG/arrow-circle-broken-left.svg" />
    </app-button>
  </app-modal>
</template>

<script lang="ts" setup>
  defineProps<{
    isShowModal: boolean;
  }>();

  const emits = defineEmits<{
    close: [];
  }>();

  function onCloseModal() {
    emits('close');
  }
</script>

<style lang="scss" scoped>
  .auth-modal {
    &__icon {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: center;

      border-radius: 10px;
      background: white;

      @include setSquareSize(52px);

      &_blue {
        background: var(--blue-bg);
      }
    }

    &__image {
      @include setSquareSize(30px);
    }

    &__block {
      display: flex;
      flex-direction: column;

      padding: 15px 10px;

      border-radius: 20px;
      background: white;

      @include toRem(font-size, 15px);
      @include toRem(line-height, 22px);

      &_blue {
        flex-direction: row;
        justify-content: space-between;

        margin-bottom: 18px;

        color: white;
        background: var(--blue-main);

        gap: 10px;
      }
    }

    &__invite {
      position: fixed;
      z-index: 100;
      bottom: 35px;

      width: calc(100vw - 40px);
    }

    &__flex {
      display: flex;
      align-items: center;

      width: 100%;
      margin-bottom: 120px;

      color: var(--gray-black);

      gap: 10px;
    }
  }
</style>
