<template>
  <label class="input-group input-phone" for="input-tel">
    <img
      :class="[
        'input-group__icon',
        {
          'input-group__icon_active': isValid,
          'input-group__icon_error': isError,
        },
      ]"
      data-cy="input-phone"
      alt=""
      src="@/assets/icons/SVG/phone-icon.svg"
    />
    <span class="input-group__text">+7</span>
    <app-input
      id="input-tel"
      v-model="phoneInput"
      v-mask="'### ###-##-##'"
      autocomplete="tel"
      class="input-group__input"
      placeholder="XXX XXX-XX-XX"
      type="tel"
      data-testid="input"
      @paste.prevent="onClearPaste"
      @focus="onResetError"
    />
  </label>
</template>

<script lang="ts">
  import { mask } from 'vue-the-mask';

  export default {
    name: 'AppInputPhone',
    directives: { mask },
  };
</script>

<script lang="ts" setup>
  import { computed, ref, watch } from 'vue';
  import AppInput from '@/components/atoms/AppInput.vue';

  const props = defineProps<{
    isError: boolean;
  }>();

  const emits = defineEmits<{
    validate: [{ value: boolean; phone: string }];
    hasError: [];
  }>();
  const phoneInput = ref('');
  const isValid = ref(false);

  const phoneNumber = computed(
    () =>
      '+7' +
      [...phoneInput.value]
        .filter(char => char !== ' ' && isFinite(+char))
        .join(''),
  );

  function onClearPaste(event: ClipboardEvent): void {
    let pasteData =
      event?.clipboardData?.getData('text/plain').replace(/[^+\d]/g, '') || '';
    if (pasteData[0] === '+') {
      phoneInput.value = pasteData?.slice(2);
    } else phoneInput.value = pasteData?.slice(1);
  }

  function onResetError(): void {
    if (props.isError) {
      emits('hasError');
    }
  }

  watch(phoneNumber, phone => {
    isValid.value = phone.length === 12;
  });

  watch(isValid, value => {
    emits('validate', { value, phone: phoneNumber.value });
  });
</script>

<style lang="scss" scoped>
  .input-group {
    display: flex;
    align-items: center;

    width: 100%;
    height: $ui-height;
    padding: 0 20px;

    border-radius: 50px;
    background-color: var(--light-gray);

    gap: 8px;

    &__icon {
      @include setSquareSize(20px);

      &_active {
        @include filter-blue;
      }

      &_error {
        @include filter-red;
      }
    }

    &__text {
      font-weight: 600;
    }

    &__input {
      height: 100%;

      font-weight: 600;
    }
  }
</style>
