import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import './registerServiceWorker';
import router from '@/router';
import directives from '@/directives';
import atoms from '@/components/atoms';
import components from '@/components';
import Calendar from 'primevue/calendar';
import PrimeVue from 'primevue/config';
import { primeVueConfig } from '@/primevue.config';

const pinia = createPinia();

const app = createApp(App);
directives.forEach(directive => {
  app.directive(directive.name, directive);
});

atoms.forEach(component => {
  if (component.name) app.component(component.name, component);
});

components.forEach(component => {
  if (component.name) app.component(component.name, component);
});

app.component('PrimeCalendar', Calendar);

app.use(router).use(pinia).use(PrimeVue, primeVueConfig).mount('#app');
