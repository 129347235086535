import { auth } from '@/api';
import { useCookies } from 'vue3-cookies';
import router from '@/router';
import axios, { AxiosResponse } from 'axios';
import { LocalStorageWrapper } from './localStorageWrapper';

const localStorage = new LocalStorageWrapper();

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token') as string;
  const { cookies } = useCookies();
  const cookieToken = cookies.get('accessToken');

  if (cookieToken) {
    config.headers['Authorization'] = `Bearer ${cookieToken}`;
  } else if (token) {
    config.headers['Authorization'] = token;
  }

  return config;
});

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  async (error: any) => {
    const {
      response: {
        config: { url },
      },
    } = error;
    const originalRequest = error.config;
    const { status } = error.response;
    const token = localStorage.getItem('token');
    const isRefreshTokenExpired =
      url === '/auth/refreshToken' && [401].includes(status);
    const { cookies } = useCookies();

    if (isRefreshTokenExpired) {
      localStorage.clear();
      cookies.remove('accessToken');
      await router.push('/');
      return;
    }
    if (token && [401].includes(status)) {
      originalRequest._isRetry = true;
      try {
        const newTokenData = await auth.refreshToken();
        localStorage.setItem('token', newTokenData.data.accessToken);
        cookies.set('accessToken', newTokenData.data.accessToken);
        localStorage.setItem('refreshToken', newTokenData.data.refreshToken);
        return api(originalRequest);
      } catch (e) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export default api;
