<template>
  <div>
    <div class="wrapper service-page">
      <app-header router-link="ServicePage">Выбор услуги</app-header>
      <app-swiper
        :slides="3"
        slides-per-view="1"
        space-between="10"
        thumbs-swiper=".thumbs"
      ></app-swiper>
      <app-swiper
        :slides="3"
        is-thumbs-swiper
        slides-per-view="3"
        space-between="9"
      ></app-swiper>
      <div class="service-page__info">
        <div class="service-page__name">Тапочки</div>
        <div class="service-page__price">Бесплатно</div>
      </div>
      <div class="service-page__mass">~350 г.</div>
      <div class="service-page__description">
        *Фотографии могут не соответствовать действительности
      </div>
      <hr class="service-page__divider divider" />
      <div class="service-page__order">
        <div v-if="hasParams" class="service-page__params">
          <div class="service-page__count params-block">
            <div class="params-block__title">Кол-во штук</div>
            <div
              :class="[
                'params-block__list',
                {
                  'params-block__list_center': !hasSize,
                },
              ]"
            >
              <app-button
                data-cy="reduce"
                :is-disable="count === 1"
                class="params-block__button_reduce"
                theme="inactive"
                is-square
                is-round
                @click="onReduceCount"
              >
                <img alt="" src="@/assets/icons/SVG/minus.svg" />
              </app-button>
              <div class="params-block__count" data-cy="count">
                {{ count }}
              </div>
              <app-button
                data-cy="increase"
                class="params-block__button_increase"
                theme="inactive"
                is-square
                is-round
                @click="onIncreaseCount"
              >
                <img alt="" src="@/assets/icons/SVG/plus.svg" />
              </app-button>
            </div>
          </div>
          <div v-if="hasSize" class="service-page__size params-block">
            <div class="params-block__title">Размер (EU)</div>
            <div class="params-block__list">
              <app-button
                v-for="key in sizes"
                :key="key"
                data-cy="size"
                :is-inactive="currentSize !== key"
                :is-selected="currentSize === key"
                :text="key"
                class="params-block__button"
                is-square
                is-round
                @click="onChangeSize(key)"
              >
              </app-button>
            </div>
          </div>
        </div>
        <div class="service-page__buy">
          <app-button
            class="service-page__button_buy"
            data-cy="buy"
            text="Купить сейчас"
            @click="onUpdateModal(true)"
          >
            <img alt="" src="@/assets/icons/SVG/cart.svg" />
          </app-button>
          <app-button
            data-cy="date"
            is-square
            is-round
            @click="onUpdateDatePicker(true)"
          >
            <img alt="" src="@/assets/icons/SVG/calendar_clock.svg" />
          </app-button>
        </div>
      </div>
    </div>
    <transition name="modal" mode="out-in" :duration="animationDuration">
      <date-picker
        v-if="isShowDate"
        class="service-page__date-picker"
        @show-modal="onUpdateDatePicker"
      ></date-picker>
    </transition>
    <transition name="modal" mode="out-in" :duration="animationDuration">
      <app-modal-card
        :is-show-modal="isShowModal"
        @show-modal="onUpdateModal"
      />
    </transition>
  </div>
</template>

<script lang="ts" setup>
  import { register } from 'swiper/element/bundle';
  import { onUnmounted, ref } from 'vue';
  import { Size } from '@/models/enums';
  import { getEnumValues } from '@/helper';
  import AppSwiper from '@/pages/ServiceItemPage/AppSwiper.vue';
  import AppModalCard from '@/pages/ServiceItemPage/AppModalCard.vue';
  import { animationDuration } from '@/config';

  register();
  const thumbsSwiper = ref(null);
  const setThumbsSwiper = swiper => {
    thumbsSwiper.value = swiper;
  };

  function toggleBodyScroll(lock: boolean) {
    document.body.style.overflow = lock ? 'hidden' : '';
  }

  const sizes = ref<Size[]>(getEnumValues(Size));
  const hasSize = ref(true);
  const hasParams = ref(true);
  const currentSize = ref<Size>(Size.S);
  const count = ref(1);
  const isShowDate = ref(false);

  const isShowModal = ref(false);

  function onChangeSize(size: Size): void {
    currentSize.value = size;
  }

  function onIncreaseCount(): void {
    count.value++;
  }

  function onReduceCount(): void {
    count.value--;
  }

  function onUpdateDatePicker(value: boolean): void {
    isShowDate.value = value;
    toggleBodyScroll(value);
  }

  function onUpdateModal(value: boolean): void {
    isShowModal.value = value;
    toggleBodyScroll(value);
  }

  onUnmounted(() => {
    toggleBodyScroll(false);
  });
</script>

<style lang="scss">
  .service-page {
    position: relative;

    align-items: center;
    flex-direction: column;
    flex-grow: 1;

    min-height: min-content;

    &__button_back.button {
      position: absolute;
      left: 0;

      background: transparent;

      @include setSquareSize(48px);
    }

    &__title {
      font: {
        weight: 600;
        @include toRem(size, 20px);
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;

      width: 100%;
      padding-top: 30px;

      font: {
        weight: 600;
        @include toRem(size, 20px);
      }
    }

    &__price {
      color: var(--blue-main);
    }

    &__mass {
      width: 100%;
      padding-top: 10px;

      text-align: left;

      opacity: 50%;
      color: black;

      font: {
        weight: 400;
        @include toRem(size, 20px);
      }
    }

    &__description {
      padding: 20px 0;

      opacity: 50%;
    }

    &__divider.divider {
      margin-top: auto;
    }

    &__order {
      width: 100%;
      height: min-content;
      padding: 24px 0;
    }

    &__params {
      display: flex;
      justify-content: space-between;

      padding-bottom: 20px;

      gap: 10px;
    }

    &__size {
      max-width: min-content;
    }

    &__buy {
      display: flex;
      justify-content: space-between;

      gap: 7px;
    }

    &__button_buy.button {
      background-color: var(--blue-main);

      &:hover {
        background: var(--blue-light);
      }
    }
  }

  .params-block {
    display: flex;
    flex-direction: column;

    width: 100%;

    font: {
      @include toRem(size, 20px);
      weight: 600;
    }

    gap: 5px;

    &__title {
      @include toRem(font-size, 14px);
    }

    &__list {
      display: flex;
      align-items: center;

      gap: 5px;
    }

    &__list_center {
      justify-content: center;

      gap: 10px;

      .params-block__button_reduce.button.button_round,
      .params-block__button_increase.button.button_round {
        width: 100%;
        max-width: 200px;

        border-radius: 50px;
      }
    }

    &__count {
      width: 40px;
      padding: 0 7px;

      text-align: center;
    }
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: all 0.15s ease-in;
  }

  .modal-leave-active {
    transition-delay: 0.1s;
  }

  .modal-enter-from,
  .modal-leave-to {
    opacity: 0;
  }

  .modal-enter-active .modal,
  .modal-leave-active .modal,
  .modal-enter-active .date-picker,
  .modal-leave-active .date-picker {
    transition: all 0.15s ease-out;
  }

  .modal-enter-active .modal,
  .modal-enter-active .date-picker {
    transition-delay: 0.1s;
  }

  .modal-enter-from .modal,
  .modal-leave-to .modal,
  .modal-enter-from .date-picker,
  .modal-leave-to .date-picker {
    transform: translateY(300px);
  }
</style>
