<template>
  <div :class="['cart-cards', { section: title }]">
    <div v-if="title" class="cart-cards__title">{{ title }}</div>
    <div v-for="card in cards?.items" :key="card.service.slug" class="card">
      <img class="logo" :src="require(`@/assets/images/spa1.png`)" alt="" />
      <div>
        <div class="title">{{ card.service.translations.ru_RU.name }}</div>
        <div class="price">
          {{ card.service.price }} руб/{{ card.service.meta?.duration }}
        </div>
      </div>
      <app-button
        class="edit"
        theme="gray"
        is-round
        is-square
        @click="editService(card.service, card.meta?.slot)"
      >
        <img src="@/assets/icons/SVG/edit.svg" alt="" />
      </app-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { Cart, IService } from '@/models';

  defineProps<{
    title?: string;
    cards: Cart | null;
  }>();

  const emits = defineEmits<{
    edit: [service: IService, date: Date | undefined];
  }>();

  function editService(service: IService, date?: Date) {
    emits('edit', service, date);
  }
</script>

<style lang="scss" scoped>
  .cart-cards {
    position: relative;

    display: flex;
    flex-direction: column;

    padding-bottom: 20px;

    gap: 13px;

    &__title {
      width: 100%;
      margin-bottom: 10px;

      color: var(--gray-black);

      font: {
        weight: 600;
        @include toRem(size, 18px);
      }

      @include toRem(line-height, 22px);
    }
  }
  .card {
    position: relative;

    display: flex;
    align-items: center;

    width: 100%;
    padding: 5px 0;

    &::after {
      position: absolute;
      bottom: -5px;

      width: calc(100% - 10px);
      height: 3px;
      margin: 0 5px;

      content: '';

      border-radius: 35px;
      background: var(--gray-main);
    }

    &:last-of-type::after {
      display: none;
    }
  }

  .logo {
    margin-right: 15px;

    border-radius: 50%;

    @include setSquareSize(60px);
    object-fit: cover;
  }

  .title {
    font-weight: 600;

    @include toRem(line-height, 19px);
  }

  .price {
    color: var(--gray-black);

    font-family: 'Montserrat', 'Proxima Nova', sans-serif;

    @include toRem(line-height, 24px);
  }

  .edit {
    margin-left: auto;

    border: none;
  }

  @media screen and (min-width: 420px) {
    .card_small {
      height: 180px;
    }
  }
</style>
