import { Category, Hotel } from '@/models';
import api from '@/utils/axios';
import { AxiosResponse } from 'axios';

export default class HotelService {
  static async getHotel(slug: string): Promise<AxiosResponse<Hotel>> {
    return api.get<Hotel>(`/hotel/${slug}`);
  }

  static async getHotelCategories(
    slug: string,
  ): Promise<AxiosResponse<Category[]>> {
    return api.get<Category[]>(`/hotel/${slug}/service-category`);
  }

  static async getCategoryDetails(
    slug: string,
  ): Promise<AxiosResponse<Category>> {
    return api.get<Category>(
      `/service-category?hotelSlug=riviera-kazan&categorySlug=${slug}`,
    );
  }

  static async searchCategoryNodes(
    query: string,
    hotelSlug: string,
  ): Promise<AxiosResponse<Category[]>> {
    return api.post<Category[]>('/service-category/search', {
      query,
      hotelSlug,
    });
  }
}
