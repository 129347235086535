<template>
  <div class="wrapper service-confirm">
    <app-header router-link="ServiceItemPage">Ваш заказ</app-header>
    <hr class="service-confirm__divider divider" />
    <div class="service-confirm__info">
      <div class="service-confirm__name">Тапочки</div>
      <div class="service-confirm__price">Бесплатно</div>
    </div>
    <hr class="service-confirm__divider divider" />
    <div class="service-confirm__date" @click="onUpdateDatePicker(true)">
      <div class="service-confirm__wrapper">
        <div class="service-confirm__icon">
          <img alt="" src="@/assets/icons/SVG/calendar.svg" />
        </div>
        <div class="service-confirm__inner">
          <div class="service-confirm__title">Дата</div>
          <div class="service-confirm__subtitle" data-cy="day">{{ day }}</div>
        </div>
      </div>
      <div class="service-confirm__wrapper">
        <div class="service-confirm__icon">
          <img alt="" src="@/assets/icons/SVG/clock.svg" />
        </div>
        <div class="service-confirm__inner">
          <div class="service-confirm__title">Время</div>
          <div class="service-confirm__subtitle" data-cy="time">{{ time }}</div>
        </div>
      </div>
    </div>
    <app-button
      class="service-confirm__button_confirm"
      text="Подтвердить заказ"
    >
      <img
        alt=""
        class="date-picker__icon"
        src="@/assets/icons/SVG/check-white-icon.svg"
      />
    </app-button>
    <transition :duration="animationDuration" mode="out-in" name="modal">
      <date-picker
        v-if="isShowDate"
        data-cy="modal"
        class="service-confirm__date-picker"
        @show-modal="onUpdateDatePicker"
      ></date-picker>
    </transition>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { LocalStorageWrapper } from '@/utils/localStorageWrapper';
  import { animationDuration } from '@/config';

  const localStorage = new LocalStorageWrapper();
  const isShowDate = ref(false);
  const day = computed(getDay);
  const time = computed(getTime);

  function toggleBodyScroll(lock: boolean) {
    document.body.style.overflow = lock ? 'hidden' : '';
  }

  function getTime() {
    if (isShowDate.value) return;
    return new Date(localStorage.getItem('date') || Date.now()).toLocaleString(
      'default',
      {
        hour: 'numeric',
        minute: 'numeric',
      },
    );
  }

  function getDay() {
    if (isShowDate.value) return;
    return new Date(localStorage.getItem('date') || Date.now()).toLocaleString(
      'default',
      {
        day: 'numeric',
        month: 'long',
      },
    );
  }

  function onUpdateDatePicker(value: boolean): void {
    isShowDate.value = value;
    toggleBodyScroll(value);
  }
</script>

<style lang="scss" scoped>
  .service-confirm {
    align-items: center;
    flex-direction: column;

    &__info {
      display: flex;
      flex-direction: column;

      width: 100%;
      padding: 24px 0;

      font-weight: 600;

      gap: 10px;
    }

    &__price {
      color: var(--blue-main);
    }

    &__date {
      display: flex;
      align-self: flex-start;
      justify-content: space-between;

      width: 100%;
      padding: 25px 20px 25px 0;

      gap: 10px;
    }

    &__icon {
      padding: 14px;

      border-radius: 10px;
      background: #e9e9fb;

      @include setSquareSize(52px);

      img {
        @include setSquareSize(24px);
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;

      gap: 15px;
    }

    &__inner {
      display: flex;
      flex-direction: column;

      gap: 5px;
    }

    &__title {
      font-weight: 600;
    }

    &__button_confirm.button {
      margin: auto 0 30px;

      background-color: var(--blue-main);

      &:hover {
        background: var(--blue-light);
      }
    }
  }
</style>
