<template>
  <component :is="componentToRender" />
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  import { useAuthStore } from '@/stores/auth';
  import { AuthPage, OnboardingWelcomePage, HistoryVisitsPage } from '@/pages';
  const store = useAuthStore();
  const isAuth = computed(() => store.isAuth);
  const isOnboardInvisible = computed(() => store.isOnboardInvisible);

  const componentToRender = computed(() =>
    isAuth.value
      ? HistoryVisitsPage
      : isOnboardInvisible.value
        ? AuthPage
        : OnboardingWelcomePage,
  );
</script>
