<template>
  <input
    :value="modelValue"
    class="input"
    data-cy="input"
    data-testid="input"
    @input="onUpdateInput"
  />
</template>

<script lang="ts">
  export default {
    name: 'AppInput',
  };
</script>

<script lang="ts" setup>
  defineProps<{
    modelValue: string;
  }>();

  const emits = defineEmits<{
    'update:modelValue': [modelValue: string];
  }>();

  function onUpdateInput(event: Event): void {
    emits('update:modelValue', (event.target as HTMLInputElement).value);
  }
</script>

<style lang="scss" scoped>
  .input {
    text-align: left;
    letter-spacing: 0.1em;

    color: black;
    border: none;
    outline: none;
    background: transparent;

    font-weight: 600;

    @include toRem(line-height, 22px);

    &::placeholder {
      color: var(--gray-disable);
    }
  }
</style>
