<template>
  <div class="category-page wrapper">
    <app-header router-link="SubCategoryPage">
      <p>Услуги отеля</p>
      <app-button class="category-page__button_change" @click="onChangeDisplay">
        <img alt="" src="@/assets/icons/SVG/list-view.svg" />
      </app-button>
    </app-header>
    <app-list
      :is-tiles="isTiles"
      :items="services"
      :func="onGoToService"
    ></app-list>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import router from '@/router';
  import AppHeader from '@/components/AppHeader.vue';
  import AppList from '@/components/AppList.vue';
  import { category } from '@/api';
  import { ICategory } from '@/models';

  const services = ref<ICategory[]>([]);
  const isTiles = ref(true);
  const route = useRoute();

  function onChangeDisplay() {
    isTiles.value = !isTiles.value;
  }

  function onGoToService(): void {
    router.push({
      name: 'ServiceItemPage',
    });
  }

  onMounted(async () => {
    services.value = await category.getServices(+route.params.service);
  });
</script>

<style scoped lang="scss">
  .category-page {
    align-items: center;
    flex-direction: column;

    padding-bottom: 30px;

    gap: 5px;

    &__button_change {
      position: absolute;
      top: 12px;
      right: 0;

      background: transparent;

      @include setSquareSize(48px);
    }
  }
</style>
