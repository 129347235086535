<template>
  <div class="background"></div>
</template>

<script lang="ts">
  export default {
    name: 'AppBackground',
  };
</script>

<style lang="scss" scoped>
  .background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: fit-content;

    background: url('@/assets/images/blue-bg.png') repeat;
  }
</style>
