<template>
  <div class="services-modal__wrapper">
    <transition name="popup" mode="out-in" :duration="animationDuration">
      <popup-notification
        v-show="isPopupVisible"
        class="services-modal__popup"
        is-correct
      >
        {{ popupText }}
      </popup-notification>
    </transition>
    <transition name="modal" mode="out-in" :duration="animationDuration">
      <app-modal
        v-if="isShowModal"
        class="services-modal"
        is-rounded
        @close="onCloseModal"
      >
        <div class="services-modal__block">
          <img
            src="@/assets/images/spa7.png"
            alt=""
            class="services-modal__image"
          />
          <span class="services-modal__title">
            {{ service?.translations.ru_RU.name }}
          </span>
        </div>
        <div class="services-modal__body">
          <span class="services-modal__description">
            {{ service?.translations.ru_RU.description }}
          </span>
          <div class="services-modal__rate rate">
            <img
              v-if="service?.meta?.duration"
              alt=""
              src="@/assets/icons/SVG/timer.svg"
            />
            <div v-if="service?.meta?.duration" class="rate__value">
              {{ service?.meta?.duration }}
            </div>
            <div class="rate__icon">₽</div>
            <div class="rate__value">{{ service?.price }} руб</div>
          </div>
          <div class="services-modal__date">Выберите дату и время</div>
          <DatePicker
            v-model="date"
            inline
            :class="{ 'p-datepicker-error': isError }"
          />
          <transition name="modal" mode="out-in" :duration="animationDuration">
            <div v-if="freeHours.length && date" class="services-modal__date">
              Свободное время на
              <span>
                {{
                  date.toLocaleString('default', {
                    day: 'numeric',
                    month: 'long',
                  })
                }}
              </span>
            </div>
          </transition>
          <transition name="modal" mode="out-in" :duration="animationDuration">
            <div
              v-if="freeHours.length"
              :class="[
                'services-modal__time',
                { 'services-modal__time_error': isErrorTime },
              ]"
            >
              <img src="@/assets/icons/SVG/clock.svg" alt="" />
              <div v-if="date" class="services-modal__time_list">
                <app-button
                  v-for="(time, index) in freeTime"
                  :key="time"
                  class="services-modal__button"
                  :theme="getDateTheme(time)"
                  :text="freeHours[index]"
                  @click="onChangeTime(time)"
                />
              </div>
            </div>
          </transition>
          <service-gallery :images="images" />
          <app-message v-if="message" class="services-modal__message" is-error>
            {{ message }}
          </app-message>
        </div>
        <app-button
          class="services-modal__book"
          theme="active"
          :text="buttonText"
          data-cy="invite"
          @click="bookService"
        >
          <img alt="" src="@/assets/icons/SVG/check-white-icon.svg" />
        </app-button>
        <div class="services-modal__gradient"></div>
      </app-modal>
    </transition>
  </div>
</template>

<script lang="ts" setup>
  import { computed, onMounted, ref, watch } from 'vue';
  import { IService } from '@/models';
  import DatePicker from 'primevue/datepicker';
  import { ServiceGallery } from '..';
  import { useOrderStore } from '@/stores/cart';
  import { animationDuration } from '@/config';

  const props = defineProps<{
    isShowModal: boolean;
    service: IService | null;
    completedDate?: string;
  }>();

  const emits = defineEmits<{
    close: [];
  }>();

  const cart = useOrderStore();
  const isPopupVisible = ref(false);
  const buttonText = computed(() =>
    props.completedDate ? 'Изменить' : 'Добавить в корзину',
  );

  const popupText = computed(() =>
    props.completedDate ? 'Услуга изменена' : 'Услуга добавлена в корзину',
  );

  const images = ref([
    'spa.png',
    'spa1.png',
    'spa2.png',
    'spa3.png',
    'spa4.png',
    'spa5.png',
    'spa6.png',
  ]);

  const date = ref<Date | null>(null);
  const freeTime = ref<number[]>([]);
  const freeHours = computed(() =>
    freeTime.value?.map(timestamp => {
      const date = new Date(timestamp);
      return date.toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit',
      });
    }),
  );
  const currentTime = ref(0);
  const isError = ref(false);
  const isErrorTime = ref(false);
  const isLoading = ref(false);
  const isOffline = ref(false);
  const timer = ref();
  const message = computed(() => {
    if (isError.value) {
      return 'Вы не выбрали дату!';
    } else if (isErrorTime.value) {
      return 'Вы не выбрали время!';
    }
    return '';
  });

  watch(
    () => date.value,
    async () => {
      if (date.value && props.service) {
        freeTime.value = await cart.getFreeTime(props.service.id, date.value);
      }
      return [];
    },
  );

  function onCloseModal() {
    const scrollY = document.body.style.top;
    document.body.classList.remove('modal_status_default');
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    emits('close');
    date.value = null;
    freeTime.value = [];
    currentTime.value = 0;
    isError.value = isErrorTime.value = false;
  }

  function showPopup() {
    clearTimeout(timer.value);
    isPopupVisible.value = false;
    timer.value = setTimeout(() => {
      isPopupVisible.value = true;
    }, animationDuration);
    timer.value = setTimeout(() => {
      isPopupVisible.value = false;
    }, 5000);
  }

  function onChangeTime(time: number): void {
    currentTime.value = time;
  }

  function scrollToEndModal() {
    const servicesModal = document.querySelector('.services-modal__body');
    if (servicesModal?.scrollHeight)
      servicesModal.scrollTo({
        top: servicesModal.scrollHeight,
        behavior: 'smooth',
      });
  }

  function bookService() {
    if (!date.value) {
      isError.value = true;
      scrollToEndModal();
    } else if (!currentTime.value) {
      isErrorTime.value = true;
      scrollToEndModal();
    } else {
      if (props.completedDate && props.service) {
        cart.editService(props.service, new Date(currentTime.value));
      } else if (props.service) {
        cart.addService(props.service, 1, new Date(currentTime.value));
      }
      showPopup();
      onCloseModal();
    }
  }

  function getDateTheme(time: number) {
    switch (time) {
      case currentTime.value:
        return 'active';
      case props.completedDate ? Date.parse(props.completedDate) : 0:
        return 'light';
      default:
        return 'inactive';
    }
  }

  watch(
    () => date.value,
    () => {
      isError.value = false;
      if (!freeTime.value?.includes(currentTime.value)) currentTime.value = 0;
    },
  );

  watch(
    () => currentTime.value,
    () => {
      isErrorTime.value = false;
    },
  );

  watch(
    () => props.isShowModal,
    () => {
      if (props.isShowModal && props.completedDate) {
        date.value = new Date(props.completedDate);
        currentTime.value = Date.parse(props.completedDate.toString());
      }
    },
  );

  onMounted(() => {
    if (!navigator.onLine) {
      isLoading.value = true;
      isError.value = true;
      isOffline.value = true;
    }
    window.addEventListener('offline', () => {
      isLoading.value = true;
      isError.value = true;
      isOffline.value = true;
    });
    window.addEventListener('online', () => {
      isLoading.value = false;
      isError.value = false;
      isOffline.value = false;
    });

    if (props.completedDate) {
      date.value = new Date(props.completedDate);
    }
  });
</script>

<style lang="scss" scoped>
  .services-modal {
    z-index: 1002;

    &__wrapper {
      z-index: 1002;
    }

    &__icon {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: center;

      border-radius: 10px;
      background: white;

      @include setSquareSize(52px);
    }

    &__popup.notification {
      top: 75px;
    }

    &__body {
      position: relative;

      display: flex;
      overflow-y: scroll;
      align-items: center;
      flex-direction: column;

      width: 100%;
      padding-bottom: 150px;

      transition: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__description {
      width: 100%;
      margin-bottom: 15px;
      padding: 0 8px;

      color: var(--gray-black);

      @include toRem(line-height, 19.5px);
    }

    &__image {
      overflow: hidden;

      border-radius: 50%;

      @include setSquareSize(60px);
      object-fit: cover;
    }

    &__block {
      display: flex;
      align-items: center;

      width: 100%;
      height: 85px;
      margin-bottom: 15px;
      padding: 0 8px;

      background: white;

      font: {
        weight: 600;
        @include toRem(size, 20px);
      }

      gap: 15px;
      @include toRem(line-height, 24.3px);
    }

    &__title {
      width: calc(100% - 142px);
    }

    &__book {
      position: fixed;
      z-index: 100;
      bottom: 35px;

      width: calc(100vw - 40px);

      border: none;
    }

    &__gradient {
      position: fixed;
      bottom: 0;
      left: 10px;

      width: calc(100% - 20px);
      height: 152px;

      pointer-events: none;

      background: linear-gradient(180deg, transparent 0%, #ffffff 86.8%);
    }

    &__date {
      width: 100%;
      padding: 18px 8px;

      color: var(--gray-black);

      font: {
        weight: 600;
        @include toRem(size, 18px);
      }

      span {
        color: var(--blue-main);
      }
    }

    &__rate.rate {
      display: flex;
      align-items: center;

      width: 100%;
      height: 70px;
      padding: 24px 20px;

      border-radius: 30px;
      background: var(--light-gray);

      gap: 10px;

      img {
        @include setSquareSize(23px);
      }
    }

    &__time {
      display: flex;
      align-self: flex-start;

      width: 100%;
      padding: 0 8px;

      gap: 5px;

      &_error img {
        @include filter-red;
      }

      &_list {
        display: flex;
        overflow: auto;

        gap: 5px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &__button {
      height: auto !important;
      padding: 7.8px 15px;

      color: black;
      background: white;

      @include toRem(font-size, 15px);
      @include toRem(line-height, 18px);

      &.button_light {
        color: white;
        border: 1px solid var(--blue-main);
        background: var(--blue-main);
      }

      &.on-hover {
        background: var(--light-gray);
      }
    }

    .rate {
      &__icon {
        margin-left: 5px;

        text-align: center;

        color: white;
        border-radius: 50%;
        background: var(--blue-main);

        font-family: 'Montserrat', 'Proxima Nova', sans-serif;
        font-size: 16px;
        line-height: 23px;

        @include setSquareSize(23px);
      }

      &__value {
        font-weight: 600;
      }
    }
  }
</style>
<style lang="scss">
  .p-datepicker {
    width: 100%;

    border: 1px solid transparent;
    border-radius: 30px;
  }

  .p-datepicker-error {
    border-color: var(--red-error);
    border-radius: 30px;
  }

  .p-datepicker-panel.p-component {
    padding: 0;

    border: none;
    border-radius: 30px;
    background: var(--light-gray);

    .p-datepicker-header {
      padding: 10px 25px 10px 15px;

      border-bottom: 2px solid #0000001a;
      background: var(--light-gray);

      font-weight: 600;
    }

    .p-datepicker-weekday {
      font-weight: 600;
    }
    .p-datepicker-title {
      color: black;

      font-weight: 600;

      gap: 5px;
    }

    .p-datepicker-select-month,
    .p-datepicker-select-year {
      padding: 0;
    }

    .p-datepicker-month-selected,
    .p-datepicker-year-selected {
      background: var(--blue-main);
    }

    .p-datepicker-day-selected {
      background: var(--blue-main) !important;
    }

    .p-datepicker-day-selected:focus-visible {
      outline: none;
      box-shadow: none;
    }

    .p-datepicker-today > .p-datepicker-day {
      background: var(--gray-main);
    }

    .p-datepicker-day-cell {
      padding: 0.05rem;
    }

    .p-button-text.p-button-secondary {
      border: 1px solid var(--gray-main);
      border-radius: 50px;
      background: white;
    }

    .p-datepicker-day-view {
      width: calc(100% - 24px);
      margin: 12px;
    }

    .p-button-icon {
      @include filter-blue;
    }
  }
</style>
