import api from '@/utils/axios';
import axios, { AxiosResponse } from 'axios';
import { CodeLogin, UserLogin } from '@/models/types';
import { ICode, RefreshResponse } from '@/models';
import { useCookies } from 'vue3-cookies';
import { LocalStorageWrapper } from '@/utils/localStorageWrapper';

const localStorage = new LocalStorageWrapper();

export default class AuthService {
  static async getSmsCode(data: CodeLogin): Promise<AxiosResponse<ICode>> {
    return api.post<ICode>('/auth/code/sms', data);
  }

  static async getCallCode(data: CodeLogin): Promise<AxiosResponse<ICode>> {
    return api.post<ICode>('/auth/code/call', data);
  }

  // отправление кода на email
  // static async getEmailCode(data: CodeLogin): Promise<AxiosResponse<ICode>> {
  //   return api.post<ICode>('/auth/getCode/email', data);
  // }

  static async loginUser(
    data: UserLogin,
  ): Promise<AxiosResponse<RefreshResponse>> {
    return api.post<RefreshResponse>('/auth/login', data);
  }

  static async refreshToken(): Promise<AxiosResponse<RefreshResponse>> {
    const refreshToken: string = localStorage.getItem('refreshToken') as string;

    return axios.post<RefreshResponse>(
      '/auth/refresh',
      {
        refreshToken,
      },
      {
        baseURL: process.env.VUE_APP_API_URL,
      },
    );
  }

  static async saveTokens(data: RefreshResponse): Promise<void> {
    const { cookies } = useCookies();
    localStorage.setItem('token', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
    cookies.set('accessToken', data.accessToken);
  }

  static async deleteTokens(): Promise<void> {
    const { cookies } = useCookies();
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('phone');
    cookies.remove('accessToken');
    localStorage.setItem('isOnboardInvisible', 'true');
  }
}
