<template>
  <div>
    <div class="history-page__visits">
      <div class="history-page__title"></div>
      <div class="history-page__visit"></div>
      <div class="history-page__visit"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .history-page {
    &__title {
      width: 25%;
      height: 24px;
      margin: 10px 0;

      border-radius: 4px;
      background-color: var(--blue-bg);
    }
    &__visits {
      display: flex;
      flex-direction: column;

      width: 100%;

      gap: 5px;
    }

    &__visit {
      position: relative;

      width: 100%;
      height: 225px;

      animation: pulse 2s infinite;

      border-radius: 25px;
      background-color: var(--blue-bg);
    }
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
</style>
