<template>
  <app-modal v-if="isShowModal">
    <app-button
      class="modal__button_accept"
      data-cy="confirm"
      text="Запомнить карту"
      @click="onConfirmService(true)"
    >
      <img
        alt=""
        class="modal__icon"
        src="@/assets/icons/SVG/check-white-icon.svg"
      />
    </app-button>
    <app-button
      class="modal__button_reject"
      text="Нет, не надо"
      @click="onConfirmService(false)"
    />
  </app-modal>
</template>

<script lang="ts" setup>
  import router from '@/router';
  import AppModal from '@/components/atoms/AppModal.vue';

  defineProps<{
    isShowModal: boolean;
  }>();

  function onConfirmService(isSave: boolean): void {
    if (isSave) {
      saveCard();
    }
    router.push({
      name: 'ServiceConfirmPage',
    });
  }

  function saveCard(): void {
    console.log('сохранил карту');
  }
</script>

<style lang="scss" scoped>
  .modal {
    height: auto;
    padding: 24px 20px !important;

    gap: 8px;

    &__button_accept.button {
      max-width: 1000px;

      background: var(--blue-main);
    }

    &__button_reject.button {
      max-width: 1000px;
      margin-top: auto;

      color: var(--blue-main);
      border: 1px solid var(--gray-main);
      background: transparent;
    }
  }
</style>
