<template>
  <auth-template class="auth-page" is-small is-logo-visible>
    <template #default>
      <app-input-phone
        :is-error="isError"
        class="auth-page__input"
        @validate="onValidatePhone"
        @has-error="onPhoneError"
      >
      </app-input-phone>
    </template>
    <template #buttons>
      <app-message
        v-if="isError"
        is-error
        class="auth-page__message"
        data-cy="error"
      >
        {{ errorMessage }}
      </app-message>
      <span class="auth-page__text text_resend">
        Нажав на кнопку “Далее” я соглашаюсь с
        <a class="link" href="">политикой конфиденциальности</a>
      </span>
      <div class="auth-page__send scroll">
        <app-button
          :theme="theme"
          :is-disable="!isActiveButton || isLoading"
          :is-loading="isLoading"
          text="Далее"
          data-cy="send"
          @click="onSendCode"
        >
          <img
            alt=""
            class="button__icon"
            src="@/assets/icons/SVG/arrow-circle-broken-left.svg"
          />
        </app-button>
      </div>
      <teleport to=".container">
        <app-button
          class="auth-page__invite"
          theme="common"
          text="Вход по приглашению"
          data-cy="invite"
          @click="onEnterInvite"
        >
          <img
            alt=""
            class="button__icon"
            src="@/assets/icons/SVG/arrow-circle-broken-left.svg"
          />
        </app-button>
      </teleport>
    </template>
  </auth-template>
</template>

<script lang="ts" setup>
  import { computed, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useAuthStore } from '../stores/auth';
  import { AuthTemplate } from '@/pages';
  import router from '@/router';
  import { LocalStorageWrapper } from '@/utils/localStorageWrapper';

  const route = useRoute();
  const localStorage = new LocalStorageWrapper();
  const store = useAuthStore();
  const isValid = ref(false);
  const phoneNumber = ref('');
  const isError = ref(false);
  const email = ref('');
  const errorMessage = ref('');
  const isLoading = ref(false);
  const isValidEmail = computed((): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.value);
  });
  const isActiveButton = computed(
    () => (isValid.value && !isError.value) || isValidEmail.value,
  );

  const theme = computed(() => {
    return isActiveButton.value ? 'active' : '';
  });

  function onValidatePhone(obj: { value: boolean; phone: string }): void {
    isValid.value = obj.value;
    phoneNumber.value = obj.phone;
  }

  function onPhoneError(): void {
    isError.value = false;
  }

  async function onSendCode(): Promise<void> {
    isLoading.value = true;
    await store
      .sendSmsCode({
        login: phoneNumber.value,
      })
      .then(() => {
        localStorage.setItem('phone', phoneNumber.value);
        router.push({
          name: 'CodePage',
          query: { hotel: route.query.hotel },
        });
      })
      .catch(() => {
        isError.value = true;
        errorMessage.value =
          'Вы истратили все попытки, попробуйте авторизоваться позже';
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  function onEnterInvite(): void {
    router.push({
      name: 'InviteCodePage',
    });
  }

  onMounted(() => {
    if (!navigator.onLine) {
      isLoading.value = true;
      isError.value = true;
    }
    window.addEventListener('offline', () => {
      isLoading.value = true;
      isError.value = true;
    });
    window.addEventListener('online', () => {
      isLoading.value = false;
      isError.value = false;
    });
  });
</script>

<style lang="scss" scoped>
  .auth-page {
    .block {
      height: 256px !important;
    }
    &__info {
      text-align: center;

      color: var(--gray-disable);

      @include toRem(line-height, 22px);
    }

    &__header {
      display: flex;
      align-items: center;

      width: 100%;
      padding: 8px;
    }

    &__logo {
      min-height: 60px;

      background: url('@/assets/images/logo.png') no-repeat center / cover;

      @include setSquareSize(60px);

      &_big {
        width: 115px;
        height: 23px;
        margin-left: 12px;

        background: url('@/assets/icons/SVG/logo-hotelkey.svg') no-repeat center /
          cover;
      }
    }

    &__button {
      margin-left: auto;
    }

    &__buttons {
      display: flex;

      width: 100%;
      margin-top: 30px;
      padding: 0 5px;

      gap: 5px;
    }

    &__input,
    &__input-group.input {
      margin-top: auto;
    }

    &__text {
      position: absolute;
      bottom: 110px;

      width: 100%;

      transition: all 0.1s ease;
      text-align: left !important;

      color: var(--gray-disable);

      font-weight: 600;

      @include toRem(line-height, 22px);
    }

    &__invite {
      position: absolute;
      z-index: 100;
      bottom: 35px;
      left: 50%;

      display: none;

      width: calc(100% - 40px);
      max-width: 1000px;

      transform: translateX(-50%);
    }

    &__message {
      margin-top: 205px !important;
    }

    &__send {
      position: absolute;
      bottom: -15px;

      width: 100%;

      transition: all 0.1s ease;
    }

    &__input-group.input {
      display: flex;
      align-items: center;

      width: 100%;
      height: $ui-height;
      padding: 0 28px;

      border-radius: 50px;
      background-color: var(--light-gray);

      gap: 8px;

      &::placeholder {
        font-weight: 400;
      }
    }
  }
</style>
