<template>
  <div class="wrapper order-page">
    <div class="order-page__header">
      <app-button
        class="header__button"
        theme="gray"
        is-square
        @click="onReturnBack"
      >
        <img alt="" src="@/assets/icons/SVG/arrow.svg" />
      </app-button>
      <div class="order-page__logo"></div>
    </div>
    <div class="order-page__picture"></div>
    <div class="order-page__block">
      <div class="order-page__icon"></div>
      <div class="order-page__name skeleton-text"></div>
      <div class="order-page__date skeleton-text"></div>
    </div>
    <div class="order-page__inner">
      <div class="order-page__title skeleton-text"></div>
      <div class="order-page__date skeleton-text"></div>
      <div class="order-page__item order">
        <div class="order__icon skeleton-circle"></div>
        <div class="order__name skeleton-text"></div>
      </div>
      <div class="order-page__item order">
        <div class="order__icon skeleton-circle"></div>
        <div class="order__name skeleton-text"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import router from '@/router';

  function onReturnBack() {
    router.push({
      name: 'WelcomePage',
    });
  }
</script>

<style lang="scss" scoped>
  .order-page {
    position: relative;

    align-items: center;
    flex-direction: column;

    padding: 0 !important;

    &__icon {
      position: absolute;
      top: -30px;
      left: 50%;

      transform: translateX(-50%);
      animation: pulse 2s infinite;

      border: 5px white solid;
      border-radius: 50%;
      background-color: var(--blue-bg);

      @include setSquareSize(75px);
    }

    &__header {
      z-index: 1001;

      display: flex;
      align-items: center;

      width: 100%;
      padding: 8px;
    }

    &__logo {
      width: 100px;
      height: 20px;
      margin-left: 12px;

      background: url('@/assets/icons/SVG/logo-hotelkey.svg') no-repeat center /
        cover;
    }

    &__picture {
      position: relative;

      overflow: hidden;

      width: 100%;
      height: 220px;
      margin-top: 4px;

      border-radius: 30px;
      background-color: var(--blue-bg);
    }

    &__block {
      position: relative;
      top: -100px;

      display: flex;
      align-items: center;
      flex-direction: column;

      width: 100%;
      height: 130px;

      border-radius: 25px 25px 0px 0px;
      background-color: white;
    }

    &__cover.skeleton-cover {
      position: relative;

      width: 100%;
      height: 80px;

      animation: pulse 2s infinite;

      border-radius: 20px;
      background-color: var(--blue-bg);
    }

    &__name.skeleton-text,
    &__date.skeleton-text,
    &__title.skeleton-text {
      width: 70%;
      height: 20px;
      margin-top: 54px;

      animation: pulse 2s infinite;

      border-radius: 4px;
      background-color: var(--blue-bg);
    }

    &__date.skeleton-text {
      width: 50%;
      margin: 5px 0 10px;
    }

    &__title.skeleton-text {
      margin-top: 20px;
    }

    &__actions {
      display: flex;

      margin-top: auto;

      gap: 5px;
    }

    &__action.skeleton-circle {
      animation: pulse 2s infinite;

      border-radius: 50%;
      background-color: var(--blue-bg);

      @include setSquareSize(52px);
    }

    &__inner {
      position: relative;
      top: -100px;

      display: flex;
      flex-direction: column;

      width: 100%;

      .order-page__date {
        margin: 8px 0;
      }
    }

    .order {
      display: flex;
      align-items: center;

      margin-bottom: 5px;

      &__icon.skeleton-circle {
        animation: pulse 2s infinite;

        border-radius: 50%;
        background-color: var(--blue-bg);

        @include setSquareSize(60px);
      }

      &__name.skeleton-text,
      &__cost.skeleton-text {
        width: 30%;
        height: 20px;
        margin-left: 15px;

        animation: pulse 2s infinite;

        border-radius: 4px;
        background-color: var(--blue-bg);
      }
    }
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
</style>
