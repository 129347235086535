<template>
  <div class="hotel-menu">
    <div class="hotel-menu__block">
      <transition-group name="list" tag="ul" class="hotel-menu__categories">
        <li
          v-for="item in categories"
          :key="`category-${item.slug}`"
          class="hotel-menu__category"
          @click="onCloseSearch(item)"
        >
          <span>{{ item.translations.ru_RU.name }}</span>
        </li>
      </transition-group>
      <transition-group name="list">
        <ul
          v-for="category in categories"
          :key="category.slug"
          class="hotel-menu__services"
        >
          <div class="hotel-menu__title">
            {{ category.translations.ru_RU.name }}
          </div>
          <li
            v-for="service in category.services"
            :key="service.slug"
            class="hotel-menu__service"
            @click="bookService(service)"
          >
            <div class="hotel-menu__icon">
              <img src="@/assets/images/spa2.png" alt="" />
            </div>
            <span class="hotel-menu__text">
              {{ service.translations.ru_RU.name }}
            </span>
          </li>
        </ul>
      </transition-group>
      <div v-if="!categories?.length" class="hotel-menu__info">
        По результатам поиска ничего не было найдено
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'SearchResults',
  };
</script>

<script lang="ts" setup>
  import { onMounted, onUnmounted } from 'vue';
  import { Category, IService } from '@/models';

  defineProps<{
    categories?: Category[];
  }>();

  const emits = defineEmits<{
    book: [service: IService];
    close: [category: Category];
  }>();

  function bookService(service: IService) {
    emits('book', service);
  }

  function onCloseSearch(category: Category) {
    emits('close', category);
  }

  onMounted(() => {
    const scrollY = window.scrollY;
    document.body.classList.add('modal_status_default');
    document.body.style.top = `-${scrollY}px`;
  });

  onUnmounted(() => {
    const scrollY = document.body.style.top;
    document.body.classList.remove('modal_status_default');
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  });
</script>

<style lang="scss" scoped>
  .hotel-menu {
    position: absolute;
    z-index: 1001;
    top: 0;
    left: 0;

    width: 100%;
    height: calc(var(--height, 1vh) * 100);
    padding: 72px 20px 0px;

    background: white;

    &__block {
      overflow-y: auto;

      height: 100%;
      padding-bottom: 60px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__categories {
      display: flex;
      overflow: visible;
      flex-wrap: wrap;

      width: 100%;
      height: min-content !important;

      gap: 5px;
    }

    &__category {
      overflow: hidden;
      flex: 1 0 auto;

      width: auto !important;
      height: min-content !important;
      margin-bottom: 5px;
      padding: 9px;

      transition: all 0.5s;
      text-align: center;

      border-radius: 48px;
      background: #d9d9d95c;

      font: {
        @include toRem(size, 18px);
        weight: 600;
      }

      @include none-select;
      @include toRem(line-height, 22px);
    }

    &__services {
      display: flex;
      flex-direction: column;

      padding-top: 15px;

      transition: all 0.5s;

      gap: 15px;
    }

    &__service {
      display: flex;
      align-items: center;
      flex-direction: row;

      width: 100%;

      cursor: pointer;

      gap: 15px;
    }

    &__title {
      color: var(--gray-black);

      font: {
        weight: 600;
        @include toRem(size, 18px);
      }
      line-height: 22px;
    }

    &__icon {
      position: relative;

      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;

      transition: all 0.2s ease;

      border-radius: 50%;

      @include setSquareSize(60px);

      img {
        object-fit: cover;
        @include setSquareSize(100%);
      }
    }

    &__text {
      font: {
        @include toRem(size, 20px);
        weight: 600;
      }

      @include toRem(line-height, 24.4px);
    }

    &__info {
      margin-top: 200px;

      text-align: center;

      color: var(--gray-black);

      line-height: 22px;

      @include toRem(font-size, 18px);
    }

    .list-enter-from,
    .list-leave-to {
      transform: scale(0.9);

      opacity: 0;
    }

    .list-leave-active {
      position: absolute;
      z-index: -1;
    }
  }
</style>
