import axios from 'axios';
import { ICategory, Service, SubCategory } from '@/models';

const MOCK = process.env.VUE_APP_MOCK === 'true';

let categories: ICategory[] = [];
let subcategories: SubCategory[] = [];
let services: Service[] = [];

async function loadData() {
  if (MOCK) {
    const data = await import('@/json/categories.json');
    categories = data.categories;
    subcategories = data.subcategories;
    services = data.services;
  }
}

export default class CategoryService {
  static async getCategories(): Promise<ICategory[]> {
    if (MOCK) {
      await loadData();
      return categories;
    }
    return (
      await axios.get<ICategory[]>('/categories', {
        baseURL: process.env.VUE_APP_JSON_URL,
      })
    ).data;
  }

  static async getSubCategories(id: number): Promise<SubCategory[]> {
    if (MOCK) {
      await loadData();
      return subcategories.filter(
        subcategory => subcategory.category_id === id,
      );
    }
    return (
      await axios.get<SubCategory[]>('/subcategories', {
        baseURL: process.env.VUE_APP_JSON_URL,
        params: { category_id: id },
      })
    ).data;
  }

  static async getServices(id: number): Promise<Service[]> {
    if (MOCK) {
      await loadData();
      return services.filter(service => service.subcategory_id === id);
    }
    return (
      await axios.get<Service[]>('/services', {
        baseURL: process.env.VUE_APP_JSON_URL,
        params: { subcategory_id: id },
      })
    ).data;
  }
}
