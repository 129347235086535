// Страница для авторизации по email
<template>
  <auth-template>
    <template #default>
      <div class="link_close" @click="onReturnBack">
        <app-button class="button_close">
          <img
            alt=""
            class="icon_close"
            src="@/assets/icons/SVG/arrow-narrow-left.svg"
          />
        </app-button>
      </div>
      <app-input-code
        v-model:code="code"
        :is-correct="isValid"
        :is-error="isError"
        is-phone
        class="input-tile"
        @has-error="onUpdateError"
      />
      <div
        :class="[
          'button_resend',
          {
            button_resend_active: isActive,
          },
        ]"
        data-cy="login"
        @click="onSendCode"
      >
        <img
          alt=""
          class="icon_resend"
          src="@/assets/icons/SVG/corner-up-right-icon.svg"
        />
        <p>Повторно отправить код</p>
      </div>
      <p v-if="timeResend > 0" class="text_resend">
        через {{ timeResend }} секунд
      </p>
    </template>
    <template #buttons>
      <app-button
        :disabled="!isValid || isError"
        :is-active="isValid && !isError"
        class="button_bottom"
        text="Отправить"
        @click="checkCode"
      />
    </template>
  </auth-template>
</template>

<script lang="ts" setup>
  import { onMounted, onUnmounted, ref, watch } from 'vue';
  import { AxiosError } from 'axios';
  import router from '@/router';
  import { useAuthStore } from '@/stores/auth';
  import { AuthResponse, RefreshResponse } from '@/models';
  import { AuthTemplate } from '@/pages';
  import { LoginTypes, PasswordTypes } from '@/models/enums';

  const store = useAuthStore();
  const code = ref('');
  const timeResend = ref(10);
  const timer = ref<any>(undefined);
  const isActive = ref(false);
  const isError = ref(false);
  const isValid = ref(false);
  const errorMessage = ref<AxiosError>();

  async function startTimer(): Promise<void> {
    timer.value = setInterval(() => {
      if (--timeResend.value === 0) {
        stopTimer();
        changeResend();
      }
    }, 1000);
  }

  function changeResend(): void {
    isActive.value =
      !isActive.value &&
      ![429].includes(errorMessage.value?.response?.status as number);
  }

  function stopTimer(): void {
    clearTimeout(timer.value);
  }

  function onUpdateError(value: boolean): void {
    isError.value = value;
  }

  async function onSendCode(): Promise<void> {
    if (isActive.value) {
      await store
        .sendSmsCode({
          login: localStorage.getItem('phone') as string,
        })
        .then(changeResend)
        .then(() => {
          timeResend.value = 10;
        })
        .then(startTimer)
        .catch((error: any) => {
          isError.value = true;
          changeResend();
          stopTimer();
          errorMessage.value = error;
        });
    }
  }

  async function checkCode(): Promise<void> {
    await store
      .checkCode({
        login: localStorage.getItem('phone') as string,
        loginType: LoginTypes.EMAIL,
        password: code.value,
        passwordType: PasswordTypes.OTP,
      })
      .then((data: RefreshResponse) => {
        store.saveTokens(data);
        router.push({
          name: 'Home',
        });
      })
      .catch((error: any) => {
        isError.value = true;
        errorMessage.value = error;
      });
  }

  async function onReturnBack(): Promise<void> {
    await store.logout();
    await router.push('/');
  }

  onMounted(startTimer);
  onUnmounted(stopTimer);

  watch(code, () => {
    if (code.value.length === 4) {
      isValid.value = true;
    } else {
      isValid.value = false;
    }
  });
</script>
