<template>
  <div class="hotel-skeleton">
    <div class="hotel-skeleton__header">
      <span class="hotel-skeleton__subtitle"></span>
      <span class="hotel-skeleton__title"></span>
      <div
        class="hotel-skeleton__subtitle hotel-skeleton__subtitle_large"
      ></div>
      <div class="hotel-skeleton__navbar">
        <div class="hotel-skeleton__slide"></div>
        <div class="hotel-skeleton__slide"></div>
      </div>
    </div>
    <div class="hotel-skeleton__main">
      <div class="hotel-skeleton__card hotel-skeleton__card_small"></div>
      <div class="hotel-skeleton__card hotel-skeleton__card_small"></div>
      <div class="hotel-skeleton__card hotel-skeleton__card_large"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .hotel-skeleton {
    display: flex;
    flex-direction: column;

    width: 100%;

    &__header {
      position: relative;

      display: flex;
      flex-direction: column;

      padding-bottom: 140px;

      gap: 10px;
    }

    &__subtitle {
      display: block;

      width: 70%;
      height: 25px;

      animation: pulse 2s infinite;

      border-radius: 4px;
      background-color: var(--blue-bg);
      &_large {
        width: 80%;
      }
    }

    &__title {
      width: 100%;
      height: 45px;

      animation: pulse 2s infinite;

      border-radius: 4px;
      background-color: var(--blue-bg);
    }

    &__main {
      display: flex;
      flex-wrap: wrap;

      gap: 5px;
    }

    &__card {
      position: relative;

      width: 100%;

      animation: pulse 2s infinite;

      border-radius: 25px;
      background-color: var(--blue-bg);
      &_large {
        height: 250px;
      }
      &_small {
        width: calc(50% - 2.5px);
        height: 160px;
      }
    }
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

  @media screen and (min-width: 420px) {
    .hotel-skeleton {
      &__card_small {
        height: 180px;
      }
    }
  }
</style>
