// primevue.config.js

import Lara from '@primevue/themes/lara';

export const primeVueConfig = {
  locale: {
    dayNamesMin: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
    monthNames: [
      'Январь,',
      'Февраль,',
      'Март,',
      'Апрель,',
      'Май,',
      'Июнь,',
      'Июль,',
      'Август,',
      'Сентябрь,',
      'Октябрь,',
      'Ноябрь,',
      'Декабрь,',
    ],
    monthNamesShort: [
      'Янв',
      'Февр',
      'Март',
      'Апр',
      'Май',
      'Июнь',
      'Июль',
      'Авг',
      'Сент',
      'Окт',
      'Нояб',
      'Дек',
    ],
  },
  theme: {
    preset: Lara,
    options: {
      prefix: 'p',
      darkModeSelector: '.my-app-dark',
      cssLayer: false,
    },
  },
};
