<template>
  <div class="modal-wrapper">
    <div
      ref="modal"
      class="date-picker"
      @mousemove.self="onDrag"
      @mouseup.self="onStopDrag"
      @touchend.self="onStopDrag"
      @touchmove.self="onDrag"
    >
      <div class="date-picker__line"></div>
      <div
        class="date-picker__title"
        @mousemove.self="onDrag"
        @mouseup.self="onStopDrag"
        @touchend.self="onStopDrag"
        @touchmove.self="onDrag"
      >
        Выберите дату и время<br />
        доставки вашего заказа
      </div>
      <date-time-picker
        v-model="correctDate"
        data-cy="pick"
        :date-order="['month', 'date']"
        :drag-sensitivity="1"
        :height="isSmallDevice ? '6em' : '10em'"
        :pattern="{
          month: 'MMM',
          date: 'ddd DD',
          hour: 'HH',
          minute: 'mm',
          dividerDate: '',
          dividerTime: '',
        }"
        :scroll-sensitivity="0.1"
        :touch-sensitivity="1"
        class="date-picker__wheel"
        format="YYYY-MM-DD HH:mm"
      />
      <app-button
        class="date-picker__button"
        text="Сохранить выбор"
        data-cy="save"
        @click="onSaveDate"
      >
        <img
          alt=""
          class="date-picker__icon"
          src="@/assets/icons/SVG/check-white-icon.svg"
        />
      </app-button>
    </div>
    <app-background @click="onCloseModal" />
  </div>
</template>

<script lang="ts">
  export default {
    name: 'DatePicker',
  };
</script>

<script lang="ts" setup>
  import { onMounted, onUpdated, ref } from 'vue';
  import { DateTimePicker } from 'vue-drumroll-datetime-picker';
  import { DateTranslation } from '@/models/enums';
  import 'vue-drumroll-datetime-picker/dist/style.css';
  import { LocalStorageWrapper } from '@/utils/localStorageWrapper';

  const localStorage = new LocalStorageWrapper();

  const emits = defineEmits<{
    showModal: [value: boolean];
  }>();
  const date = ref(new Date(localStorage.getItem('date') || Date.now()));

  const correctDate = ref(
    date.value.getFullYear() +
      '-' +
      ('0' + (date.value.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.value.getDate()).slice(-2) +
      ' ' +
      ('0' + date.value.getHours()).slice(-2) +
      ':' +
      ('0' + date.value.getMinutes()).slice(-2),
  );

  const modal = ref(null);
  const initialY = ref(0);
  const currentY = ref();
  const isSmallDevice = ref(window.innerWidth <= 360);

  function onDrag(event) {
    const touch = event.touches ? event.touches[0] : event;
    currentY.value = touch.clientY;
  }

  function onStopDrag(): void {
    const threshold = 30;
    if (currentY.value - initialY.value > threshold) {
      onCloseModal();
    } else {
      modal.value.style.top = `${initialY.value}px`;
    }
  }

  function onCloseModal() {
    emits('showModal', false);
  }

  function onSaveDate() {
    localStorage.setItem('date', correctDate.value.toString());
    onCloseModal();
  }

  function translateDate() {
    const elements = document.querySelectorAll('.vue-scroll-picker-item');

    elements.forEach(element => {
      const text = element.textContent;

      if (text) {
        const day = text.split(' ')[0] as keyof typeof DateTranslation;

        if (DateTranslation[day]) {
          element.textContent = text.replace(day, DateTranslation[day]);
        }
      }
    });
  }

  onUpdated(() => {
    translateDate();
  });

  onMounted(() => {
    translateDate();
    setTimeout(
      () => (initialY.value = modal.value.getBoundingClientRect().top),
      500,
    );
  });
</script>

<style lang="scss">
  .date-picker {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;

    display: flex;
    overflow-y: hidden;
    align-items: center;
    flex-direction: column;

    width: 100%;
    min-height: 290px;
    padding: 48px 20px 24px !important;

    user-select: none;
    transition: top 0.1s linear;

    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background: white;

    &__line {
      position: absolute;
      top: 18px;
      left: 50%;

      width: 67px;
      height: 4px;

      transform: translateX(-50%);

      border-radius: 18px;
      background: var(--blue-main);
    }

    &__title {
      text-align: center;

      color: var(--blue-main);

      font: {
        weight: 600;
        @include toRem(size, 20px);
      }

      @include toRem(line-height, 24.36px);
    }

    &__wheel {
      @include toRem(font-size, 26.85px);
      @include toRem(line-height, 32px);
    }

    &__button.button {
      max-width: 1000px;
      margin-top: auto;

      background: var(--blue-main);
    }

    .v-drumroll-picker__group {
      gap: 20px;
    }

    .vue-scroll-picker-item-selected {
      color: var(--blue-main);
    }

    .vue-scroll-picker-layer-bottom {
      height: calc(50% - 0.6em);
    }

    .vue-scroll-picker-layer-top {
      height: calc(50% - 0.6em);
    }
  }

  .modal-wrapper {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
</style>
