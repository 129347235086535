<template>
  <div class="category-page wrapper">
    <app-header router-link="WelcomePage">Категории отеля</app-header>
    <app-list is-list :items="categories" :func="onGoToNextPage"></app-list>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import router from '@/router';
  import AppHeader from '@/components/AppHeader.vue';
  import AppList from '@/components/AppList.vue';
  import { category } from '@/api';
  import { ICategory } from '@/models';

  const categories = ref<ICategory[]>([]);
  const route = useRoute();

  function onGoToNextPage(id: number): void {
    if (route.params.id)
      router.push({
        name: 'ServicePage',
        params: { id: route.params.id, service: id },
      });
    else
      router.push({
        name: 'SubCategoryPage',
        params: { id: id },
      });
  }

  onMounted(async () => {
    if (route.params.id) {
      categories.value = await category.getSubCategories(+route.params.id);
    } else {
      categories.value = await category.getCategories();
    }
  });
</script>

<style scoped lang="scss">
  .category-page {
    align-items: center;
    flex-direction: column;

    padding-bottom: 30px;

    gap: 5px;
  }
</style>
