<template>
  <Transition name="fade" mode="out-in">
    <div v-if="isTiles" key="tiles" class="list">
      <TransitionGroup
        :css="false"
        name="list"
        @enter="onEnter"
        @leave="onLeave"
        @before-enter="onBeforeEnter"
      >
        <div
          v-for="item in items"
          :key="item.id"
          ref="elements"
          data-cy="item"
          :data-index="item.loading_id"
          class="item tile"
          @click="func(item.id)"
        >
          <img
            :src="require(`@/assets/images/${item.image}.png`)"
            alt=""
            class="item__image"
          />
          <div class="item__info">
            <div class="item__title">{{ item.title }}</div>
            <div class="item__description">{{ item.description }}</div>
            <div class="item__transit">
              <p>Перейти</p>
              <img alt="" src="@/assets/icons/SVG/arrow-narrow-left.svg" />
            </div>
          </div>
        </div>
      </TransitionGroup>
    </div>
    <div v-else-if="isList" key="list" class="list">
      <TransitionGroup
        :css="false"
        name="list"
        @enter="onEnter"
        @leave="onLeave"
        @before-enter="onBeforeEnter"
      >
        <div
          v-for="item in items"
          :key="item.id"
          ref="elements"
          data-cy="item"
          :data-index="item.loading_id"
          class="item"
          @click="func(item.id)"
        >
          <div class="item__info">
            <div class="item__title">{{ item.title }}</div>
            <div class="item__description">{{ item.description }}</div>
          </div>
          <app-button class="item__button" is-round is-square>
            <img :src="require(`@/assets/icons/SVG/${item.icon}.svg`)" alt="" />
          </app-button>
        </div>
      </TransitionGroup>
    </div>
    <div v-else key="simple" class="list">
      <TransitionGroup
        :css="false"
        name="list"
        @enter="onEnter"
        @leave="onLeave"
        @before-enter="onBeforeEnter"
      >
        <div
          v-for="item in items"
          :key="item.id"
          ref="elements"
          data-cy="item"
          :data-index="item.loading_id"
          class="item item_simple"
          @click="func(item.id)"
        >
          <img
            :src="require(`@/assets/icons/SVG/${item.icon}.svg`)"
            alt=""
            class="item__icon"
          />
          <div class="item__name">{{ item.title }}</div>
        </div>
      </TransitionGroup>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
  import { gsap } from 'gsap';
  import { onUpdated, ref } from 'vue';
  import { ICategory, Service, SubCategory } from '@/models';
  import { setHoverBehaviorArray } from '@/helper';

  withDefaults(
    defineProps<{
      isTiles?: boolean;
      isList?: boolean;
      items: ICategory[] | SubCategory[] | Service[];
      func: (id: number) => void;
    }>(),
    {
      isTiles: false,
      isList: false,
    },
  );

  const elements = ref([]);

  function onBeforeEnter(el): void {
    el.style.opacity = 0;
    el.style.transform = 'translateX(30px)';
  }

  function onEnter(el, done): void {
    gsap.to(el, {
      opacity: 1,
      x: 0,
      delay: (el.dataset.index % 10) * 0.08,
      onComplete: done,
    });
  }

  function onLeave(el, done): void {
    gsap.to(el, {
      opacity: 0,
      x: 100,
      delay: (el.dataset.index % 10) * 0.08,
      onComplete: done,
    });
  }

  onUpdated(() => {
    setHoverBehaviorArray(elements.value);
  });
</script>

<style lang="scss" scoped>
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 89px;
    padding-right: 25px;

    transition: all 0.1s ease;

    border: 1px solid var(--gray-main);
    border-radius: 10px;

    gap: 5px;
    @include none-select();

    &_simple {
      height: 65px;

      border-radius: 20px;
    }

    &.on-hover {
      background: var(--light-gray);
    }

    &__button.on-hover {
      background: transparent;
    }

    &__image {
      height: 100%;

      border-radius: 10px;

      aspect-ratio: 1/1;
      object-fit: cover;
    }

    &__info {
      display: flex;
      flex-direction: column;

      margin-right: auto;
      padding-left: 20px;

      gap: 5px;
    }

    &__name {
      margin-right: auto;
      padding-left: 20px;

      font: {
        @include toRem(size, 20px);
        weight: 600;
      }
    }

    &__title {
      font: {
        @include toRem(size, 18px);
        weight: 600;
      }
    }

    &__description {
      opacity: 0.5;

      @include toRem(font-size, 12px);
    }

    &__transit {
      display: flex;

      padding-top: 15px;

      color: var(--blue-main);

      font: {
        @include toRem(size, 12px);
        weight: 600;
      }

      gap: 5px;
    }

    &__icon {
      padding-left: 20px;
    }
  }

  .list {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;

    gap: 5px;
  }

  .item.tile {
    height: 136px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.15s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  @media screen and (max-width: 360px) {
    .item {
      height: 70px;
    }
  }
</style>
