export enum DateTranslation {
  'Mon' = 'Пн',
  'Tue' = 'Вт',
  'Wed' = 'Ср',
  'Thu' = 'Чт',
  'Fri' = 'Пт',
  'Sat' = 'Сб',
  'Sun' = 'Вс',
  'Jan' = 'Янв',
  'Feb' = 'Фев',
  'Mar' = 'Март',
  'Apr' = 'Апр',
  'May' = 'Май',
  'Jun' = 'Июн',
  'Jul' = 'Июл',
  'Aug' = 'Авг',
  'Sep' = 'Сен',
  'Oct' = 'Окт',
  'Nov' = 'Ноя',
  'Dec' = 'Дек',
}
