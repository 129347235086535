import { ref } from 'vue';
import { defineStore } from 'pinia';
import { ICode, IUser, RefreshResponse, Token } from '@/models';
import { auth } from '@/api';
import { CodeLogin, UserLogin } from '@/models/types';
import { LocalStorageWrapper } from '@/utils/localStorageWrapper';

export const useAuthStore = defineStore('auth', () => {
  const user = ref({} as IUser);
  const localStorage = new LocalStorageWrapper();
  const isAuth = ref(Boolean(localStorage.getItem('token')));
  const isOnboardInvisible = ref(
    Boolean(localStorage.getItem('isOnboardInvisible')),
  );
  async function sendSmsCode(params: CodeLogin): Promise<ICode> {
    const { data } = await auth.getSmsCode(params);
    return data;
  }

  async function sendCallCode(params: CodeLogin): Promise<ICode> {
    const { data } = await auth.getCallCode(params);
    return data;
  }

  // отправка кода на email
  // async function sendEmailCode(params: CodeLogin): Promise<ICode> {
  //   const { data } = await auth.getEmailCode(params);
  //   return data;
  // }

  async function checkCode(params: UserLogin): Promise<RefreshResponse> {
    const { data } = await auth.loginUser(params);
    saveTokens(data);
    isAuth.value = true;
    return data;
  }

  async function checkAuth(): Promise<void> {
    if (isAuth.value) {
      const { data } = await auth.refreshToken();
      await saveTokens(data);
    }
  }

  async function saveTokens(data: Token): Promise<void> {
    await auth.saveTokens(data);
  }

  function changeOnboardVisible(): void {
    isOnboardInvisible.value = true;
  }

  async function logout(): Promise<void> {
    isAuth.value = false;
    await auth.deleteTokens();
  }

  return {
    user,
    isAuth,
    isOnboardInvisible,
    sendSmsCode,
    sendCallCode,
    // sendEmailCode,
    checkCode,
    checkAuth,
    saveTokens,
    logout,
    changeOnboardVisible,
  };
});
