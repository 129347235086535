import { isLocalStorageSupported } from '@/helper';

export class LocalStorageWrapper {
  private isSupported: boolean;

  constructor() {
    this.isSupported = isLocalStorageSupported();
  }

  setItem<T>(key: string, value: T): void {
    if (this.isSupported) {
      try {
        const jsonValue = JSON.stringify(value);
        localStorage.setItem(key, jsonValue);
      } catch (e) {
        if (e instanceof DOMException && e.code === 22) {
          console.error('localStorage overflow');
        } else {
          console.error('Error saving to localStorage', e);
        }
      }
    }
  }

  getItem<T>(key: string): T | null {
    if (this.isSupported) {
      try {
        const jsonValue = localStorage.getItem(key);
        return jsonValue ? JSON.parse(jsonValue) : null;
      } catch (e) {
        console.error('Error reading from localStorage', e);
        return null;
      }
    }
    return null;
  }

  removeItem(key: string): void {
    if (this.isSupported) {
      localStorage.removeItem(key);
    }
  }

  clear(): void {
    if (this.isSupported) {
      localStorage.clear();
    }
  }
}
