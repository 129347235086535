<template>
  <div class="services-skeleton">
    <div class="services-skeleton__logo"></div>
    <div class="services-skeleton__title"></div>
    <div class="services-skeleton__main">
      <div class="services-skeleton__card"></div>
      <div class="services-skeleton__card"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .services-skeleton {
    position: relative;
    left: -20px;

    display: flex;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;

    width: calc(100% + 40px);
    padding: 0 10px;
    padding-bottom: 124px;

    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background: white;

    &__logo {
      position: absolute;
      top: -30px;
      left: 50%;

      transform: translateX(-50%);
      animation: pulse 2s infinite;

      border-radius: 50%;
      background-color: var(--blue-bg);

      @include setSquareSize(80px);
    }
    &__title {
      width: 50%;
      height: 30px;
      margin: 54px auto 20px;

      animation: pulse 2s infinite;
      text-align: center;

      border-radius: 4px;
      background-color: var(--blue-bg);
    }

    &__main {
      display: flex;
      flex-wrap: wrap;

      gap: 5px;
    }

    &__card {
      position: relative;

      width: calc(50% - 2.5px);
      height: 160px;

      animation: pulse 2s infinite;

      border-radius: 25px;
      background-color: var(--blue-bg);
    }
  }

  @media screen and (min-width: 420px) {
    .services-skeleton {
      &__card {
        height: 180px;
      }
    }
  }
</style>
