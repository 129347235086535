<template>
  <auth-template class="invite-page">
    <template #default>
      <div class="link_close" @click="onReturnBack">
        <app-button class="button_close">
          <img
            alt=""
            class="icon_close"
            src="@/assets/icons/SVG/arrow-narrow-left.svg"
          />
        </app-button>
      </div>
      <app-input-code
        v-model:code="code"
        :is-correct="isValid"
        :is-error="isError"
        :is-small="false"
        class="input-tile"
        @has-error="onUpdateError"
      />
      <p v-if="!isError" class="text_resend invite-page__resend">
        Введите пригласительный код
      </p>
      <div v-else class="invite-page__message">
        <app-message is-error> Неправильный код! </app-message>
        <p>Обратитесь на ресепшен отеля</p>
      </div>
    </template>
    <template #buttons>
      <p class="text">
        Пригласительный код можно получить на стойке ресепшена или через
        приложение у проживающего в номере пользователя
      </p>
      <teleport to="body">
        <app-button
          :disabled="!isValid || isError"
          :is-active="isValid && !isError"
          class="invite-page__send"
          text="Отправить"
          data-cy="check"
          @click="checkCode"
        />
      </teleport>
    </template>
  </auth-template>
</template>

<script lang="ts" setup>
  import { ref, watch } from 'vue';
  import { AuthTemplate } from '@/pages';
  import router from '@/router';

  const code = ref('');
  const isError = ref(false);
  const isValid = ref(false);

  function onUpdateError(value: boolean): void {
    isError.value = value;
  }

  async function checkCode(): Promise<void> {
    router.push({
      name: 'PhoneConfirmPage',
    });
  }

  async function onReturnBack(): Promise<void> {
    await router.push({
      name: 'AuthPage',
    });
  }

  watch(code, () => {
    if (code.value.length === 6) {
      isValid.value = true;
    } else {
      isValid.value = false;
    }
  });
</script>

<style lang="scss" scoped>
  .invite-page {
    &__message {
      display: flex;
      align-items: center;
      flex-direction: column;

      width: 75%;
      margin-top: 15px;

      color: var(--red-error);

      font-weight: 600;

      @include toRem(line-height, 22px);

      .message {
        margin: 0 auto;
      }
    }

    &__send {
      position: absolute;
      bottom: calc(
        ((var(--height, 1vh) * 100) - (var(--vh, 1vh) * 100)) * 1.3 + 38px
      );
      left: 20px;

      width: calc(100% - 40px);

      transition: all 0.1s ease;
    }

    &__resend {
      margin-top: 20px;
    }
  }
</style>
