<template>
  <div
    :class="[
      'input-group',
      {
        'input-group_error': isError,
        'input-group_small': isSmall,
      },
    ]"
  >
    <label
      v-for="label in length"
      :key="label"
      :class="{
        'input-group__label_focused': focusLabel === label,
        'input-group__label_error': isError,
        'input-group__label_correct': isCorrect,
        'input-group__label_small': isSmall,
      }"
      :for="'input' + label"
      data-cy="input-code"
      class="input-group__label"
    >
      <app-input
        :id="'input' + label"
        ref="inputs"
        v-model="codeInput[label - 1]"
        class="input-group__input"
        maxlength="1"
        placeholder="0"
        type="number"
        data-testid="code"
        @blur="onBlur"
        @focus="onFocusInput($event, label)"
        @input="onHandleInput($event, label)"
        @keydown="onClearInput($event, label)"
        @select.prevent
        @paste.prevent="onCorrectPaste"
      ></app-input>
    </label>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'AppInputCode',
  });
</script>

<script lang="ts" setup>
  import { computed, ref, watch } from 'vue';
  import AppInput from '@/components/atoms/AppInput.vue';

  const props = defineProps<{
    isError: boolean;
    isCorrect: boolean;
    isSmall: boolean;
  }>();
  const emits = defineEmits<{
    hasError: [value: boolean];
    'update:code': [code: string];
  }>();

  const focusLabel = ref<number | null>(null);
  const inputs = ref<any[]>([]);
  const length = props.isSmall ? 4 : 6;
  const codeInput = ref(new Array(length).fill(''));
  const codeRaw = computed(() => codeInput.value.join(''));

  function onBlur(): void {
    focusLabel.value = null;
  }

  function onFocusInput(event: Event, label: number): void {
    codeInput.value[label - 1] = '';
    focusLabel.value = label;
    if (props.isError) {
      codeInput.value = ['', '', '', '', '', ''];
      emits('hasError', false);
    }
  }

  function onHandleInput(event: Event, inputIndex: number): void {
    const input = inputs.value[inputIndex - 1].$el;
    const value = (event.target as HTMLInputElement).value;

    if (!/^\d$/.test(value)) {
      (event.target as HTMLInputElement).value = '';
    }

    if (value && value.length === 1) {
      if (inputIndex < inputs.value.length) {
        inputs.value[inputIndex].$el.focus({ preventScroll: true });
      } else {
        inputs.value[inputIndex - 1].$el.blur();
      }
    }

    if (input.value.length === 1) {
      input.value = value;
    }
    emits('update:code', codeRaw.value);
  }

  function onClearInput(event: KeyboardEvent, inputIndex: number): boolean {
    const value = inputs.value[inputIndex - 1].$el.value;
    if (event.key === 'Backspace' && inputIndex > 1 && !value) {
      inputs.value[inputIndex - 2].$el.focus({ preventScroll: true });
    }
    if (+event.key >= 0 && +event.key <= 9) {
      (event.target as HTMLInputElement).value = '';
    }
    return false;
  }

  function onCorrectPaste(event: ClipboardEvent): void {
    for (let i = 0; i < codeInput.value.length; i++) {
      codeInput.value[i] = event?.clipboardData?.getData('text/plain')[i] || '';
    }
  }

  watch(codeRaw, value => {
    emits('update:code', value);
  });
</script>

<style lang="scss" scoped>
  .input-group {
    display: flex;
    justify-content: center;

    padding: 5px 7px;

    transition: all 0.1s ease;

    border: 1px solid transperent;
    border-radius: 1.075rem;

    gap: 3px;

    &_error {
      border: 1px solid var(--red-error);
    }

    &__input {
      text-align: center;

      @include setSquareSize(100%);
      @include toRem(font-size, 20px);
      @include none-select;
      caret-color: transparent;

      &:focus::placeholder {
        color: var(--gray-black);
      }
    }

    &__label {
      position: relative;

      width: 14vw;
      max-width: 70px;
      height: 14vw;
      max-height: 70px;

      border-radius: 1.05rem;
      background: var(--light-gray);

      &_correct {
        background: #e6e5fb80;

        .input-group__input {
          color: var(--blue-main);
        }
      }

      &_error {
        background: #fbe5e580;

        .input-group__input {
          color: var(--red-error);
        }
      }

      &::after {
        position: absolute;
        bottom: -7px;
        left: 50%;

        display: block;

        width: 0;
        height: 2px;

        content: '';
        transition: all 0.2s ease;
        transform: translateX(-50%);

        border-radius: 2px;
        background: var(--blue-main);
      }

      &_focused::after {
        width: 65%;

        content: '';
      }
    }
  }
</style>
