<template>
  <div class="wrapper history-page">
    <div class="history-page__header">
      <div class="history-page__back">
        <div class="history-page__notification" />
        <app-button
          class="history-page__button_back"
          theme="gray"
          is-square
          @click="onMoveToMenu"
        >
          <img alt="" src="@/assets/icons/SVG/menu.svg" />
        </app-button>
      </div>
      <div class="history-page__logo_big"></div>
    </div>
    <div class="history-page__body">
      <div class="history-page__title">История посещений</div>
      <Suspense>
        <template #default>
          <history-visits />
        </template>
        <template #fallback>
          <history-visits-skeleton />
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { HistoryVisits, HistoryVisitsSkeleton } from '..';
  import router from '@/router';

  function onMoveToMenu() {
    router.push({
      name: 'MenuPage',
    });
  }
</script>

<style lang="scss" scoped>
  .history-page {
    align-items: center;
    flex-direction: column;

    &__title {
      margin: 15px 0 5px;

      @include subtitle-text;
    }

    &__header {
      position: fixed;
      z-index: 1002;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;

      width: 100%;
      min-height: 75px;
      padding: 0 20px;

      transition: all 0.2 ease;

      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      background: white;
    }

    &__logo {
      min-height: 50px;

      background: url('@/assets/images/logo.png') no-repeat center / cover;

      @include setSquareSize(50px);

      &_big {
        width: 100px;
        height: 20px;
        margin-left: 12px;

        background: url('@/assets/icons/SVG/logo-hotelkey.svg') no-repeat center /
          cover;
      }
    }

    &__button {
      width: 127px;
      margin-top: 6px;
      margin-left: auto;

      .button__icon {
        rotate: 180deg;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 50px auto;

      border-radius: 10px;
      background: var(--blue-bg);

      @include setSquareSize(110px);
    }

    &__text {
      text-align: center;

      color: var(--gray-black);

      line-height: 22px;

      @include toRem(font-size, 18px);
    }

    &__body {
      width: 100%;
      margin: 75px 0 40px;
    }
  }
</style>
