<template>
  <auth-template class="phone-page" title="Подтверждение телефона">
    <template #default>
      <div class="link_close" @click="onReturnBack">
        <app-button class="button_close">
          <img
            alt=""
            class="icon_close"
            src="@/assets/icons/SVG/arrow-narrow-left.svg"
          />
        </app-button>
      </div>
      <p class="phone-page__info">
        Укажите номер телефона, чтобы мы могли вас информировать о важных
        мероприятиях на территории отеля
      </p>
      <app-input-phone
        v-focus
        :is-error="isError"
        class="phone-page__input"
        @validate="onValidatePhone"
        @has-error="onPhoneError"
      >
      </app-input-phone>
    </template>
    <template #buttons>
      <teleport to="body">
        <app-button
          :is-disable="!isValid || isError"
          :is-active="isValid && !isError"
          class="phone-page__send"
          text="Получить код по SMS"
          data-cy="sms"
          @click="onSendSmsCode"
        />
        <app-button
          :is-disable="!isValid || isError"
          class="phone-page__invite"
          :is-common="isValid && !isError"
          text="Получить звонок с кодом"
          data-cy="call"
          @click="onSendCallCode"
        >
          <img
            alt=""
            class="button__icon"
            src="@/assets/icons/SVG/phone-incoming.svg"
          />
        </app-button>
      </teleport>
    </template>
  </auth-template>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { useAuthStore } from '../stores/auth';
  import { AuthTemplate } from '@/pages';
  import router from '@/router';

  const store = useAuthStore();
  const isValid = ref(false);
  const phoneNumber = ref('');
  const isError = ref(false);

  function onValidatePhone(obj: { value: boolean; phone: string }): void {
    isValid.value = obj.value;
    phoneNumber.value = obj.phone;
  }

  function onPhoneError(): void {
    isError.value = false;
  }

  async function onSendSmsCode(): Promise<void> {
    await store
      .sendSmsCode({
        login: phoneNumber.value,
      })
      .then(() => {
        localStorage.setItem('phone', phoneNumber.value);
        router.push({
          name: 'CodePage',
        });
      })
      .catch(() => {
        isError.value = true;
      });
  }

  async function onSendCallCode(): Promise<void> {
    await store
      .sendCallCode({
        login: phoneNumber.value,
      })
      .then(() => {
        localStorage.setItem('phone', phoneNumber.value);
        router.push({
          name: 'CodePhonePage',
        });
      })
      .catch(() => {
        isError.value = true;
      });
  }

  function onReturnBack(): void {
    router.push({
      name: 'Home',
    });
  }
</script>

<style lang="scss" scoped>
  .phone-page {
    &__info {
      text-align: center;

      color: var(--gray-disable);

      @include toRem(line-height, 22px);
    }

    &__input,
    &__input-group.input {
      margin-top: auto;
    }

    &__invite {
      position: absolute;
      bottom: calc(
        ((var(--height, 1vh) * 100) - (var(--vh, 1vh) * 100)) * 1.3 + 38px
      );
      left: 20px;

      width: calc(100% - 40px);

      transition: all 0.1s ease;
    }

    &__send {
      position: absolute;
      bottom: calc(
        ((var(--height, 1vh) * 100) - (var(--vh, 1vh) * 100)) * 1.3 + 98px
      );
      left: 20px;

      width: calc(100% - 40px);

      transition: all 0.1s ease;
    }
  }
</style>
