<template>
  <div class="modal-wrapper" @click.self="onCloseModal">
    <div :class="['modal', { modal_round: isRounded }]">
      <div
        ref="modalClose"
        class="modal__close"
        data-swipe-threshold="20"
        data-swipe-unit="px"
        data-swipe-timeout="500"
        data-swipe-ignore="false"
      ></div>
      <app-button
        v-if="isRounded"
        is-square
        theme="gray"
        class="modal__close"
        @click="onCloseModal"
      >
        <img src="@/assets/icons/SVG/close-icon.svg" alt="" />
      </app-button>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'AppModal',
  };
</script>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import '@/assets/js/swiped-events.min.js';

  defineProps<{
    isRounded?: boolean;
  }>();

  const emits = defineEmits<{
    close: [];
  }>();

  const modalClose = ref<HTMLElement>();

  function onCloseModal() {
    const scrollY = document.body.style.top;
    document.body.classList.remove('modal_status_default');
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    emits('close');
  }

  onMounted(() => {
    const scrollY = window.scrollY;
    document.body.classList.add('modal_status_default');
    document.body.style.top = `-${scrollY}px`;
    if (modalClose.value) {
      modalClose.value.addEventListener('swiped-down', () => {
        onCloseModal();
      });
    }
  });
</script>

<style lang="scss">
  .modal {
    position: absolute;
    bottom: 0;
    left: 10px;

    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;

    width: calc(100% - 20px);
    max-height: calc(100% - 80px);
    margin: 0 auto;
    padding: 10px 12px 0;

    user-select: none;
    transition: height 0.2s ease;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: white;
    box-shadow: 0px 0px 14.3px 0px #00000021;

    &_round {
      padding: 15px 12px 0;

      border-top-left-radius: 45px;
      border-top-right-radius: 45px;
    }

    &::after {
      position: absolute;
      top: -15px;

      width: 55px;
      height: 5px;

      content: '';

      border-radius: 30px;
      background: white;
    }

    &__close.button {
      position: absolute;
      top: 15px;
      right: 20px;
    }

    &__close {
      position: absolute;
      top: -15px;

      width: 100%;
      height: 25%;

      background-color: transparent;
    }
  }

  .modal-wrapper {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;

    display: flex;
    overflow-y: auto;
    flex-direction: column;

    width: 100%;
    height: 100%;

    background: url('@/assets/images/blue-bg.png') repeat;
  }

  .modal_status_default {
    position: fixed;
    right: 0;
    left: 0;

    overflow: hidden;
  }
</style>
