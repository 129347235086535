export enum OrderStatus {
  CREATED = 'created',
  PENDING = 'pending',
  PROCESSING = 'processing',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  REFUND = 'refund',
  REFUND_PENDING = 'refund_pending',
}

export const OrderStatusText = {
  [OrderStatus.CREATED]: 'Создан',
  [OrderStatus.PENDING]: 'В ожидании',
  [OrderStatus.PROCESSING]: 'В работе',
  [OrderStatus.CANCELED]: 'Отменен',
  [OrderStatus.COMPLETED]: 'Выполнен',
  [OrderStatus.REFUND]: 'Возврат',
  [OrderStatus.REFUND_PENDING]: 'Возврат',
};
