<template>
  <div class="service-gallery section">
    <div
      v-for="(image, index) in startImages"
      :key="`image${index}`"
      :class="['card', cardClass(index)]"
      @click="changeSwiper(index)"
    >
      <img class="image" :src="require('@/assets/images/' + image)" alt="" />
    </div>
    <transition-group
      :css="false"
      name="cards"
      @enter="onEnter"
      @leave="onLeave"
      @before-enter="onBeforeEnter"
    >
      <div
        v-for="(image, index) in endImages"
        :key="`image${index + 5}`"
        :class="['card', cardClass(index + 5)]"
        :data-index="index"
        @click="changeSwiper(index + 5)"
      >
        <img class="image" :src="require('@/assets/images/' + image)" alt="" />
      </div>
    </transition-group>
    <div class="service-gallery__button" @click="expandImages">
      <span>{{ endImages.length ? 'Свернуть' : 'Развернуть' }}</span>
      <div
        class="service-gallery__icon"
        :class="[
          'service-gallery__icon',
          {
            'service-gallery__icon_focus': endImages.length,
          },
        ]"
      >
        <img src="@/assets/icons/SVG/arrow-simple.svg" alt="" />
      </div>
    </div>
    <transition name="page" mode="out-in">
      <div
        v-show="isVisibleSwiper"
        class="service-gallery__wrapper"
        @click="changeSwiper"
      >
        <swiper-container
          ref="mainSlider"
          class="service-gallery__swiper"
          init="false"
          @click.stop
        >
          <swiper-slide
            v-for="(image, index) in images"
            :key="`slide${index}`"
            class="service-gallery__slide"
          >
            <img :src="require(`@/assets/images/${image}`)" alt="" />
          </swiper-slide>
        </swiper-container>
        <div class="service-gallery__nav">
          <div class="service-gallery__swiper-pagination" @click.stop></div>
          <app-button
            class="service-gallery__swiper-button-prev"
            theme="inactive"
            is-square
            is-round
            @click.stop
          >
            <img src="@/assets/icons/SVG/arrow-simple.svg" alt="" />
          </app-button>
          <app-button
            class="service-gallery__swiper-button-next"
            theme="inactive"
            is-square
            is-round
            @click.stop
          >
            <img src="@/assets/icons/SVG/arrow-simple.svg" alt="" />
          </app-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import { gsap } from 'gsap';
  import { register } from 'swiper/element/bundle';

  register();

  const props = defineProps<{
    title?: string;
    images: string[];
  }>();
  const mainSlider = ref();
  const largeCardPositions = [4, 7, 11, 14, 18, 21];
  const startImages = props.images.slice(0, 5);
  const endImages = ref<string[]>([]);
  const isVisibleSwiper = ref(false);

  const mainSliderParams = {
    slidesPerView: 'auto',
    centeredSlides: 'true',
    spaceBetween: '75',
    pagination: {
      el: '.service-gallery__swiper-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.service-gallery__swiper-button-next',
      prevEl: '.service-gallery__swiper-button-prev',
    },
  };

  function isMediumCard(index: number) {
    const position = index + 1;
    const isLastCard = props.images ? index === props.images.length - 1 : 0;
    const isNextCardLarge =
      largeCardPositions.includes(position + 2) &&
      !largeCardPositions.includes(position - 1);
    const isNextCardMissing = isLastCard && isNextCardLarge;
    if (largeCardPositions.includes(position) || isNextCardMissing) {
      return true;
    }
    return false;
  }

  function isLargeCard(index: number) {
    const position = index + 1;
    const isLastCard = props.images ? index === props.images.length - 1 : 0;
    const isNextCardLarge =
      largeCardPositions.includes(position + 3) ||
      (largeCardPositions.includes(position + 1) &&
        largeCardPositions.includes(position - 2));
    const isNextCardMissing = isLastCard && isNextCardLarge;
    if (isNextCardMissing) {
      return true;
    }
    return false;
  }

  function cardClass(index: number) {
    return {
      card_medium: isMediumCard(index),
      card_small: !isMediumCard(index),
      card_large: isLargeCard(index),
    };
  }

  function expandImages() {
    if (endImages.value.length) endImages.value = [];
    else endImages.value = props.images.slice(5);
  }

  function onBeforeEnter(el: any): void {
    el.style.opacity = 0;
    el.style.transform = 'translateX(30px)';
  }

  function onEnter(el: any, done: any): void {
    gsap.to(el, {
      opacity: 1,
      x: 0,
      delay: el.dataset.index * 0.08,
      onComplete: done,
    });
  }

  function onLeave(el: any, done: any): void {
    gsap.to(el, {
      opacity: 0,
      x: 100,
      delay: (endImages.value.length - el.dataset.index + 1) * 0.08,
      onComplete: done,
    });
  }

  function changeSwiper(slide?: number) {
    if (typeof slide === 'number') {
      mainSlider.value.swiper.slideTo(slide);
      isVisibleSwiper.value = true;
    } else {
      isVisibleSwiper.value = false;
    }
  }

  onMounted(() => {
    Object.assign(mainSlider.value, mainSliderParams);
    mainSlider.value.initialize();
  });
</script>

<style lang="scss" scoped>
  .service-gallery {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    padding: 15px 0;

    gap: 5px;

    .card {
      position: relative;

      overflow: hidden;

      width: calc(66.666% - 1.666px);
      height: 111px;

      border-radius: 25px;

      .image {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
      &_small {
        width: calc(33.333% - 3.333px);
      }

      &_large {
        width: 100%;
      }
    }

    &__nav {
      display: flex;
      align-items: center;

      width: 87%;
      max-width: 320px;
      padding-top: 10px;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      padding-top: 10px;

      font-weight: 600;

      gap: 10px;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.2s ease;

      border-radius: 50%;
      background: var(--gray-main);

      @include setSquareSize(30px);

      img {
        padding-left: 3px;

        transform: rotate(90deg);
      }

      &_focus {
        transform: rotate(180deg);
      }
    }

    &__wrapper {
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      width: 100%;
      height: 100%;

      background: #000000e0;
    }

    &__slide {
      overflow: hidden;

      width: 87% !important;
      max-width: 320px;

      border-radius: 30px;

      aspect-ratio: 1/1.1;

      img {
        @include setSquareSize(100%);
        object-fit: cover;
      }
    }

    &__swiper-pagination {
      display: flex;
      justify-content: space-between;

      padding-left: 12px;

      color: white;

      font: {
        family: 'Montserrat', 'Proxima Nova', sans-serif;
        weight: 500;
        @include toRem(size, 18px);
      }
    }

    &__swiper-button-next,
    &__swiper-button-prev {
      background: white;
    }

    &__swiper-button-prev {
      margin-left: auto;
    }

    &__swiper-button-next {
      margin-left: 8px;
    }

    &__swiper-button-next img,
    &__swiper-button-prev img {
      height: 15px;

      @include filter-blue;
    }

    &__swiper-button-prev img {
      transform: rotate(180deg);
    }
  }

  @media screen and (min-width: 420px) {
    .card_small {
      height: 180px;
    }
  }
</style>
